import { useGSAP } from "@gsap/react"
import gsap from "gsap"
import ModelView from "./ModelView"
import { useRef, useState, useEffect } from "react"
import { yellowImg } from '../utils';
import * as THREE from 'three'
import { View } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { models, sizes } from "../constants";
import { animateWithGsapTimeLine } from "../utils/animations";
// import animateWithGsapTimeLine from '../utils/animations'

const Model = () => {

    const [size, setSize] = useState('small');

    const [Model, setModel] = useState({
        title: 'iPhone 15 Pro in Natural Titanium',
        color: ['#8F8A81', '#FFE7B9', '#6F6C64'],
        img: yellowImg,
    })

    // Camera control for the model view

    const cameraControlSmall = useRef();
    const cameraControlLarge = useRef();

    //Model
    const small = useRef(new THREE.Group());
    const Large = useRef(new THREE.Group());

    //Rotation

    const [smallRotation, setSmallRotation] = useState(0);
    const [LargeRotation, setLargeRotation] = useState(0);


    const tl = gsap.timeline();

    useEffect(() => {
      if (size === 'large') {
        animateWithGsapTimeLine(tl, small, smallRotation, '#view1', '#view2', {
            transform: 'translateX(-100%)',
            duration: 2
        })
      }
      if (size === 'small') {
        animateWithGsapTimeLine(tl, small, smallRotation, '#view2', '#view1', {
            transform: 'translateX(0%)',
            duration: 2
        })
      }
    }, [size])
    

    useGSAP(()=>{
        gsap.to('#heading', {
            y: 0,
            opacity: 1
        })
    }, [])

  return (
    <section className="common-padding">
        <div className="screen-max-width">
            <h1 id="heading" className="section-heading">
                Take A Closer Look.
            </h1>

            <div className="flex flex-col items-center mt-5">
                <div className="w-full h-[75vh] md:h-[90vh] overflow-hidden relative">
                    <ModelView 
                        index={1}
                        groupRef={small}
                        gsapType ="view1"
                        controlRef={cameraControlSmall}
                        setRotationState={setSmallRotation}
                        item={Model}
                        size={size}
                    />
                    
                    <ModelView 
                        index={2}
                        groupRef={Large}
                        gsapType ="view2"
                        controlRef={cameraControlLarge}
                        setRotationState={setLargeRotation}
                        item={Model}
                        size={size}
                    />

                    <Canvas className="w-full h-full " 
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        overflow: 'hidden'
                    }}
                    eventSource={document.getElementById('root')}
                    >
                        <View.Port/>
                    </Canvas>
                    </div>

                    <div className="mx-auto w-full">

                        <p className="text-sm font-light text-center mb-5">{Model.title}</p>

                        <div className="flex-center">
                            <ul className="color-container">
                                {models.map((item, i)=>(
                                    <li key={i} className="w-6 h-6 rounded-full mx-2 cursor-pointer" style={{
                                        backgroundColor: item.color[0]
                                    }}
                                    onClick={()=> setModel(item)}>

                                    </li>
                                ))}
                            </ul>

                            <button className="size-btn-container">
                                {sizes.map(({ label, value})=>(
                                    <span className="size-btn" key={label} style={{
                                        backgroundColor: size === value ? 'white' : 'transparent',
                                        color: size === value ? 'black' : 'white'
                                    }}
                                    onClick={()=> setSize(value)}>
                                        {label}
                                    </span>
                                ))}
                            </button>
                        </div>

                    </div>
            </div>
        </div>
    </section>
  )
}

export default Model